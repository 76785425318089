import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Link,
  Grid,
  List,
  ListItem,
  Breadcrumbs,
  Divider,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import GeneralError from "../../GeneralError/GeneralError";

// Component for displaying AI-generated response
const AIResponse = ({ data }) => (
  <Card sx={{ marginBottom: 4, backgroundColor: "#edeff1" }}>
    <CardContent>
      <Typography variant="h6">AI-Genererat svar</Typography>
      <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 1 }}>
        {data.generated_response}
      </Typography>
      <Divider />
      <List>
        <Typography variant="subtitle1">Källor:</Typography>
        {data.sources.map((source, index) => (
          <ListItem key={index}>
            <Link href={source.url} target="_blank" rel="noopener">
              {source.name}
            </Link>
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
);

// Component for displaying search results
const SearchResults = ({ results }) => (
  <Card sx={{ marginTop: 2, backgroundColor: "" }}>
    <CardContent>
      <Typography variant="h6">Andra sökresultat</Typography>
      <Typography variant="body1">
        Dessa är de bästa sidmatchningarna från er hemsida:
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        {results.map((result, index) => (
          <Grid item xs={12} key={index}>
            <Box sx={{ padding: 2, border: "1px solid #ddd", borderRadius: 1 }}>
              <Typography
                variant="h6"
                sx={{ marginBottom: 1, color: "primary.main" }}
              >
                <Link
                  href={result.url}
                  target="_blank"
                  rel="noopener"
                  style={{ textDecoration: "none" }}
                >
                  {result.page_name}
                </Link>
              </Typography>

              <Typography variant="body2" color="text.secondary">
                {result.caption}
              </Typography>
              <Breadcrumbs separator="›" sx={{ marginTop: 1 }}>
                {result.crumbs.map((crumb, crumbIndex) => (
                  <Link
                    key={crumbIndex}
                    href={crumb.href}
                    target="_blank"
                    rel="noopener"
                  >
                    {crumb.title}
                  </Link>
                ))}
              </Breadcrumbs>
              <Typography variant="caption" display="block">
                Senast ändrad: {new Date(result.last_modified).toLocaleString()}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);

const QuestionAnswerApp = () => {
  const [query, setQuery] = useState("");
  const [previousQuery, setPreviousQuery] = useState("");
  const [aiResponse, setAIResponse] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { customer_name } = useParams();

  const validCustomers = {
    osby: {
      name: "Osby",
      search_url: "https://www.osby.se/diverse/sokresultat.html?query=",
    },
    angelholm: {
      name: "Ängelholm",
      search_url: "https://www.engelholm.se/ovrigt/sok.html?query=",
    },
    kristianstad: {
      name: "Kristianstad",
      search_url: "https://www.kristianstad.se/omwebbplatsen/sok.6.html?query=",
    },
    borlange: {
      name: "Borlänge",
      search_url: "https://www.borlange.se/om-borlange.se/soksida?query=",
    },
    botkyrka: {
      name: "Botkyrka",
      search_url: "https://www.botkyrka.se/sokresultat?query=",
    },
    enkoping: {
      name: "Enköping",
      search_url: "https://enkoping.se/sok.html?query=",
    },
    eskilstuna: {
      name: "Eskilstuna",
      search_url:
        "https://www.eskilstuna.se/ovrigt/sokresultat?filters=Alla%20kategorier&page=1&query=",
    },
  };

  if (!validCustomers.hasOwnProperty(customer_name)) {
    return <GeneralError />;
    // Alternatively, you can use navigate to redirect:
    // navigate('/general-error');
    // return null; // Return null after redirect
  }

  const handleSearch = async () => {
    if (!query.trim()) return; // Do nothing if the query is empty

    setLoading(true);
    setError(null);
    setAIResponse(null);
    setSearchResults([]);

    const encodedQuery = encodeURIComponent(query);
    const aiResponseUrl = `https://api.redbar.tech/demo/${customer_name}/gai/${encodedQuery}`;
    const searchResultsUrl = `https://api.redbar.tech/demo/${customer_name}/vsearch/${encodedQuery}`;

    try {
      // Fetch AI response
      const aiResponsePromise = fetch(aiResponseUrl).then((res) => {
        if (!res.ok) throw new Error("Failed to fetch AI response");
        return res.json();
      });

      // Fetch search results
      const searchResultsPromise = fetch(searchResultsUrl).then((res) => {
        if (!res.ok) throw new Error("Failed to fetch search results");
        return res.json();
      });

      // Wait for both promises to resolve
      const [aiResponseData, searchResultsData] = await Promise.all([
        aiResponsePromise,
        searchResultsPromise,
      ]);

      if (aiResponseData.valid_input) {
        setSearchResults(searchResultsData);
      }

      setAIResponse(aiResponseData);
    } catch (err) {
      setError(err.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: "1024px" }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Hej {validCustomers[customer_name].name},
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          Detta är en demo för att visa hur AI kan hjälpa medborgare hitta rätt
          på er hemsida. Ställ en fråga och AI:n kommer använda information som
          finns tillgänglig på er hemsida för att formulera ett svar. Under
          svaret listas även relevanta sökresultat, precis som en vanlig
          sökmotor.
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <Box sx={{ display: "flex", marginBottom: 2 }}>
            <TextField
              label="Ställ en fråga..."
              variant="outlined"
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{ marginRight: 1 }}
              inputProps={{ maxLength: 100 }}
              error={query.length > 100} // Highlight the field in red if the limit is exceeded
              helperText={
                <Box
                  sx={{
                    textAlign: "right",
                    fontSize: "0.875rem",
                    color: "gray",
                  }}
                >
                  {query.length}/100
                </Box>
              }
            />

            <Button
              type="submit"
              variant="contained"
              onClick={() => setPreviousQuery(query)}
              disabled={loading || query.length > 100}
              sx={{
                minWidth: 0, // Prevent default button width
                width: "60px", // Set equal width and height
                height: "60px",
                borderRadius: "50%", // Make the button circular
                padding: 0, // Remove additional padding
              }}
            >
              <SendIcon />
            </Button>
          </Box>
        </form>

        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Typography color="error" sx={{ marginTop: 2 }}>
            {error}
          </Typography>
        )}

        {!loading && !error && aiResponse && searchResults && (
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Klicka{" "}
            <Link
              href={`${
                validCustomers[customer_name].search_url
              }${encodeURIComponent(previousQuery)}`}
              target="_blank"
              rel="noopener"
            >
              här
            </Link>{" "}
            för att se resultaten med er nuvarande sökfunktion på hemsidan.
          </Typography>
        )}

        {!loading && !error && aiResponse && <AIResponse data={aiResponse} />}
        {!loading && !error && searchResults.length > 0 && (
          <SearchResults results={searchResults} />
        )}
      </Box>
    </Box>
  );
};

export default QuestionAnswerApp;
